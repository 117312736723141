export const EDUCATION = [
    {
      school: "Assumption College Sriracha",
      major: "Science-Mathematics",
      degree: "Highschool (Grade 10 & 12)",
      duration: "May 2017 - March 2020",
      image_url: "./assets/images/ACS_logo.gif"
    },
    {
      school: "Tower Grove Christian Academy",
      major: "Exchange Student at USA",
      degree: "Junior Highschool (Grade 11)",
      duration: "September 2018 - May 2019",
      image_url: "./assets//images/TGCA_logo.png"
    },
    {
      school: "Mahidol Univesity",
      major: "Computer Science",
      degree: "Bachelor Science of Information and Communication Technology (ICT)",
      duration: "August 2020 - Present",
      image_url: "./assets/images/MU_logo.png"
    },

]

export const PROJECTS = [
  {
    name: "Thai2ThSL Machine Translation with Neural Network",
    level: "Team Senior Project",
    github: "",
    description: "The project introduces a machine translation system utilizing a Transformer model and mediapipe to translate Thai text input into Thai Sign Language gestures for output.",
    skills: ["Deep Learning", "NLP", "Keras", "Python"],
  },
  {
    name: "Predicting Intentions through Body Movement Annotations",
    level: "Intern Project",
    github: "",
    description: "This project aim to create a mcahine translation that can predict intentions from body language using the Sequence to Sequence model, focusing on the EGGNOG dataset.",
    skills: ["Deep Learning", "NLP", "PyTorch", "Python"],
  },
  {
    name: "Mahidol's One Stop Service",
    level: "Team Contract Project",
    description: "A digital service that allows users to request or submit all documents online, eliminating the need to physically visit the faculty or location.",
    skills: ["Blazor", "C#", "SQL", "Sprint"]
  },
  {
    name: "Wongnai's Thai Restaurant Search Engine with Next.js, Elasticsearch, and Kibana",
    level: "Team Assignment",
    github: "",
    description: "A Next.js web application, utilizing the dataset from Kaggle, alongside with Elasticsearch and Kibana to create a search engine for food vendors and restaurants in Thailand.",
    skills: ["Next.js", "ElasticSearch", "Kibana", "TailwindCSS"]
  },
  {
    name: "ShortURL Frontend Clone",
    level: "Individual Assignment",
    github: "https://noodlescupcake.github.io/shortenURL-clone/",
    description: "A fake version of ShortURL that only looks like the real thing but doesn't actually shorten any links.",
    skills: ["React.js", "TailwindCSS"]
  },
];

export const MAIN_SKILLS = [
  "Web Development",
  "AI/ML Developer",
  "Positive Attitude",
  "Work Ethic",
  "Adaptability",
];

export const WORK_EXPERIENCE = [
  {
    title: "Corporate Strategy Planning and Management - Developer Internship",
    location: "MFEC Public Company Limited",
    date: "May 2024 - Present",
    responsibilities: [
      "Fine-tuned local large language models using LoRA techniques to answer organizational questions using internal documents as the knowledge base.",
      "Evaluated the performance of multiple LLMs, embedding models, and rerankers to identify the best tools for answering financial questions.",
      "Implemented Retrieval-Augmented Generation (RAG) with a vector database and reranker, enhancing LLMs' ability to access and utilize external knowledge.",
      "Developed a Streamlit web app for generating datasets focused on fine-tuning LLMs with Retrieval-Augmented Generation (RAG).",
      "Developed a React.js and FastAPI web app that analyzes and aligns resumes with relevant job openings using Retrieval-Augmented Generation (RAG) and LLMs.",
      "Developed a React.js and FastAPI web app for PE/HR to score applicants' resumes against job information for efficient hiring decisions."
    ],
  },
  {
    title: "Bremen Spatial Cognition Center - Research Internship",
    location: "University of Bremen, Germany",
    date: "May 2023 - July 2023",
    responsibilities: [
      "Collaborated closely with Dr. Mihai Pormalan at University of Bremen to gain expertise in Natural Language Processing (NLP).",
      "Created a machine translation from human instructions to robot commands for navigational purposes.",
      "Prepared the EGGNOG dataset and focused on gesture annotations for model training.",
      "Developed a language model to anticipate intentions based on gesture annotations using the EGGNOG dataset.",
    ],
  },
  {
    title: "MUOSS - ASP.NET Core Blazor Developer",
    location: "Faculty of Information and Communication Technology, Mahidol University",
    date: "December 2022 - May 2023",
    responsibilities: [
      "Led a team for Mahidol's One Stop Service, overseeing both frontend and backend development.",
      "Utilized ASP.NET Core Blazor for the front-end development, and generated controllers and services to handle various API requests.",
      "Worked together with a UX/UI designer to implement a functional and operational front-end interface.",
      "Established a robust code foundation to guide and support other team members in their work.",
    ],
  },
  {
    title: "MUICT Laboratory Assistant - Fundamental of Programming (C)",
    location: "Faculty of Information and Communication Technology, Mahidol University",
    date: "January 2024 - May 2024",
    responsibilities: [
      "Assisted in teaching basic C programming to retake MUICT freshmen who are retaking the course.",
      "Evaluated students' assignments and laboratory work through targeted questioning to gauge their implementation and understanding of the code.",
      "Assisted students in understanding the method for resolving the problem with alternative solutions.",
    ],
  }
];

export const CERTIFICATES = [
  {
    name: "Node and Express Essentials",
    issueYear: "Jan 2024",
    issuer: "Coursera",
    link: "https://www.coursera.org/account/accomplishments/certificate/W3GBPFTU2TCJ",
    path: "./assets/images/certificates/Node and Express Essentials-1.png",
  },
  {
    name: "Introduction to Web Development with HTML, CSS, JavaScript",
    issueYear: "Jan 2024",
    issuer: "Coursera",
    link: "https://www.coursera.org/account/accomplishments/certificate/ZP2WLTCF74D2",
    path: "./assets/images/certificates/Introduction to Web Development with HTML, CSS, JavaScript-1.png",
  },
  {
    name: "Front-end Development with React",
    issueYear: "Jan 2024",
    issuer: "Coursera",
    link: "https://www.coursera.org/account/accomplishments/certificate/7VSA6RD3FPLF",
    path: "./assets/images/certificates/Front-end Development with React-1.png",
  },
  {
    name: "Advanced React",
    issueYear: "Jan 2024",
    issuer: "Coursera",
    link: "https://www.coursera.org/account/accomplishments/certificate/6FRHMXULCH9T",
    path: "./assets/images/certificates/Advanced React-1.png",
  },
  {
    name: "Database Design and Basic SQL in PostgreSQL",
    issueYear: "Jan 2024",
    issuer: "Coursera",
    link: "https://www.coursera.org/account/accomplishments/certificate/ZR2CBNFHGULC",
    path: "./assets/images/certificates/Database Design and Basic SQL in PostgreSQL-1.png",
  },
  {
    name: "Intermediate PostgreSQL",
    issueYear: "Jan 2024",
    issuer: "Coursera",
    link: "https://www.coursera.org/account/accomplishments/certificate/N3B6T5JKUP73",
    path: "./assets/images/certificates/Intermediate PostgreSQL-1.png",
  },
  {
    name: "Deep Learning 101 -- Neutral Networks and Parameterized Learning",
    issueYear: "May 2024",
    issuer: "PyImageSearch",
    link: "https://www.credential.net/10e384c3-be9e-4e8e-8797-6644720110a8",
    path: "https://api.accredible.com/v1/frontend/credential_website_embed_image/certificate/103529719",
  },
  {
    name: "Deep Learning 102 -- Optimization Methods and Regularization",
    issueYear: "May 2024",
    issuer: "PyImageSearch",
    link: "https://www.credential.net/4bdf8560-ec99-4631-a343-1ac75165a44f#gs.915g8n",
    path: "https://api.accredible.com/v1/frontend/credential_website_embed_image/certificate/103563491",
  },
  {
    name: "Natural Language Processing 101 -- Bag-of-Words (BoW) and Word2Vec",
    issueYear: "May 2024",
    issuer: "PyImageSearch",
    link: "https://www.credential.net/766478ee-ed42-4e03-963c-17a55f6341ef#gs.9159m7",
    path: "https://api.accredible.com/v1/frontend/credential_website_embed_image/certificate/103827936",
  },
  {
    name: "Natural Language Processing 102 -- RNN and LSTM",
    issueYear: "May 2024",
    issuer: "PyImageSearch",
    link: "https://www.credential.net/7a842fc4-20aa-44a0-840a-f50deb031fc5#gs.915a1u",
    path: "https://api.accredible.com/v1/frontend/credential_website_embed_image/certificate/103828515",
  },
  {
    name: "Natural Language Processing 104 -- Transformer",
    issueYear: "May 2024",
    issuer: "PyImageSearch",
    link: "https://www.credential.net/05a461d0-c16c-42d6-a49e-5fc610876ae1",
    path: "https://api.accredible.com/v1/frontend/credential_website_embed_image/certificate/103830523",
  },
  {
    name: "Generative AI with Large Language Models",
    issueYear: "May 2024",
    issuer: "DeepLearningAI",
    link: "https://coursera.org/share/104d72d96f3a821c696c00435886b9ae",
    path: "./assets/images/certificates/DeepLearningAI LLM-1.png",
  },
  {
    name: "Introduction to TensorFlow for AI, Machine Learning, and Deep Learning",
    issueYear: "May 2024",
    issuer: "DeepLearningAI",
    link: "https://coursera.org/share/bd4cf6df085395f9a5d230ad4fd7ae61",
    path: "./assets/images/certificates/Coursera TNWWT7ZBP7QF.jpg",
  },
  {
    name: "Introduction to Generative AI",
    issueYear: "May 2024",
    issuer: "Google Cloud",
    link: "https://coursera.org/share/3ab792c2fd5b73a465e19c7f99422243",
    path: "./assets/images/certificates/Coursera JRSWNWB6LL55.jpg",
  },
  {
    name: "Introduction to Large Language Models",
    issueYear: "May 2024",
    issuer: "Google Cloud",
    link: "https://coursera.org/share/423cdebd860a3d37523e27b6950f52cc",
    path: "./assets/images/certificates/Coursera CV6QNE4MY9EE.png",
  },
  {
    name: "Introduction to Responsible AI",
    issueYear: "May 2024",
    issuer: "Google Cloud",
    link: "https://coursera.org/share/290dd3039b1207f761820af006a7adf3",
    path: "./assets/images/certificates/Coursera 95NF5WZKMTRE.jpg",
  },
  {
    name: "Responsible AI: Applying AI Principles with Google Cloud",
    issueYear: "May 2024",
    issuer: "Google Cloud",
    link: "https://coursera.org/share/752b66899289ed6ea4ad2361330caf20",
    path: "./assets/images/certificates/Coursera H7K6TRNMQETR.jpg",
  },


]