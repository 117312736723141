import React from "react";
import "./Hero.css";
import { MAIN_SKILLS } from "../../utils/data";

const Hero = () => {
  return (
    <section id="hero" className="hero-container">
      <picture>
        
      </picture>
      <div className="hero-content">
        <h2 className="bg-gradient-to-bl from-zinc-100 via-zinc-200 to-zinc-300 bg-clip-text text-transparent">
          Jirayu Klinudom
        </h2>
        <p className="mx-2 bg-gradient-to-bl from-zinc-100 via-zinc-200 to-zinc-300 bg-clip-text text-transparent">
          My name is James, I'm currently a developer intern at <a href="https://www.mfec.co.th/en/" target="_blank">MFEC</a> and a graduated computer science student from MUICT with data science, and a bit of web programming experience.
        </p>
        
        <div className="highlight-skill">
          {MAIN_SKILLS.map((label) => (
            <button key={label} className="me-2 mb-2 mt-5 bg-gradient-to-bl from-cyan-500 to-blue-500 text-white font-bold py-2 px-4 rounded-full">
              <div className="bg-gradient-to-bl from-slate-50 via-slate-100 to-slate-200 bg-clip-text text-transparent">
                {label}
              </div>
            </button>
          ))}
        </div>
      </div>

      <div className="hero-img">
        <div>
          <img src="./assets/images/James.jpg" alt="" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
